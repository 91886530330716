import React from 'react'
// import { Job } from '../components/Job'
// import { SEO } from '../components/SEO'
// import { JobLayout } from '../layouts/JobLayout'
import { Job as JobType } from '../../utils/greenhouse'
import JobPageTemplate from '../../templates/JobPageTemplate'

const CreatorPartnerships = () => {
  const job: JobType = {
    id: '3',
    title: "Creator Partnerships",
    location: {
        name: "Los Angeles/Remote"
    },
    metadata: [
      {
        value: {
          min_value: "",
          max_value: "",
          unit: "USD"
        },
        value_type: "",
        name: "Salary Range"
      }
    ],
    content: `
      <div class="article">
        <div>
          <p><strong>About us</strong></p>
          <p><span style="font-weight: 400;">Fourthwall is a platform that helps creative people launch brands. Whether you’re dreaming about a fashion line, a unique hot sauce, or something digital, we make the process easy by handling all the operations and logistics—from manufacturing to fulfillment to customer support. Our goal is to empower creative people to bring their ideas to life without requiring them to become e-commerce experts.</span></p>
          <p><span style="font-weight: 400;">Our culture is built around the philosophy of putting our customers first. If you are talented, hardworking, and love helping others, we would love to talk to you about joining our team.</span></p>
          <p><span style="font-weight: 400;">Our headquarters are in Los Angeles, USA, and Poznan, Poland. We prefer people willing to work at least a couple days a week out of one of our offices but are open to remote work for the right person. If you want to find out more about who we are and how we work, you can check our <a href="https://cdn.fourthwall.com/public/fourthwall/Fourthwall%20Values.pdf" target="_blank">Company Values</a>.</span></p>
        </div>

        <div>
          <p><strong>About the role</strong></p>
          <p><span style="font-weight: 400;">We are looking for a data-driven Sales professional to help scale our outreach operations and take us from 100,000 to 1,000,000 creators.</span></p>
          <p><span style="font-weight: 400;">You will help lead our growth initiatives and optimize our outbound efforts for greater efficiency and scale. This is a unique opportunity to make a meaningful impact in the creator economy while working with an innovative team.</span></p>
        </div>

        <div>
          <p><strong>Key Responsibilities:</strong></p>
          <ul>
          <li style="font-weight: 400;"><span style="font-weight: 400;"><strong>Lead with a results-driven mindset.</strong> Set ambitious goals and lead by example to achieve and exceed them. Develop KPIs aligned with company objectives and hold the team accountable.</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;"><strong>Maintain exceptional communication standards.</strong> Prioritize rapid, clear, and effective communication with both customers and stakeholders. You predict success by response times and the quality of interactions.</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;"><strong>Obsess with helping creators succeed.</strong> Relentlessly pursue opportunities that align with our offerings and deliver maximum value to creators.</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;"><strong>Systematize and scale our outbound pipeline.</strong> Develop and execute an outreach system that drives results while ongoing data driven optimization to engage and expand our network of creators, artists, and influencers.</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;"><strong>Build and lead a high-performance engine.</strong> Recruit, train, and mentor a team of top-notch virtual assistants. Set ambitious outreach targets and ensure they're consistently surpassed.</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;"><strong>Drive strategic partnerships and close high-value deals.</strong> Cultivate relationships with leading creators and influencers. Navigate complex negotiations and close deals that significantly contribute to our growth.</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;"><strong>Innovate and continuously improve.</strong> Always seek ways to enhance processes, strategies, and outcomes. Drive initiatives that keep us ahead in the industry.</span></li>
          </ul>
        </div>

        <div>
          <p><strong>What you likely have</strong></p>
          <ul>
          <li style="font-weight: 400;"><span style="font-weight: 400;">You have a proven track record of exceeding targets and driving significant growth.</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Experience with HubSpot, Salesforce, Lemlist, Apollo, SendGrid, Outreach.io, Salesloft, and similar tools</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Ability to thrive in a fast-paced, results-driven environment</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Willingness to meet creators where/when they’re at</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">An ambition to prove you’re one of the best in the world at what you do</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Experience working with talent agencies, creator management and networks</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">An understanding of the world of content creators (YouTubers, podcasters, Tiktokkers, etc.) and how they monetize</span></li>
          </ul>
        </div>

        <div>
          <p><strong>The perks</strong></p>
          <ul>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Owning a significant role in a rapidly growing company</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Competitive compensation package</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Equity stake in Fourthwall</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Medical, Dental & Vision Insurance</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">401(k)</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Paid Parental leave, including non-birthing parents</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Flexible PTO policy</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Work in a team of experienced experts and amazing people</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Top-of-the-line Apple equipment tailored to your requirements</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Dog-friendly office just one block from the beach in LA</span></li>
          </ul>
        </div>

        <div>
          <p><span style="font-weight: 400;"><i>We are an equal opportunity employer. We value a diverse workforce and an inclusive culture. We encourage applications from all qualified individuals without regard to race, color, religion, gender, sexual orientation, gender identity or expression, age, national origin, marital status, disability, and veteran status.</i></span></p>
        </div>
      </div>
    `
  }

  return (
    <div>
      <JobPageTemplate pageContext={{
          job
      }} />
    </div>
  )
}

export default CreatorPartnerships